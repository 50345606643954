import Link from 'next/link';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { createMarkup } from 'app/scripts/utils';
import CloudinaryImage from 'app/components/common/CloudinaryImage';

import '../common/textimage.css';

import Accordion from './AccordionData';
import Container from './Container';

export default function WhyVlink({
  textimage,
  setModalCall,
  bg,
  isHomeFont,
  isBgBlack = false,
  isWhite,
}) {
  const [showIndex, setShowIndex] = useState(0);

  return (
    <div
      className={`${isBgBlack && 'bg-[#030D1EF2]'} relative xl:py-[75px] md:py-12 py-8 bg-cover bg-no-repeat bg-center before:z-[1] ${bg} before:z-0 before:content-[""] before:bottom-0 before:left-0 before:absolute before:right-0 before:top-0`}
      // style={{backgroundImage: textimage?.bg_image?.data?.attributes?.url && `url(${apiEndpoint(textimage?.bg_image?.data?.attributes?.url)})`}}
    >
      {textimage?.bg_image?.data?.attributes?.url && (
        <CloudinaryImage
          backendImgUrl={textimage?.bg_image?.data?.attributes?.url}
          className={`absolute top-0 bottom-0 left-0 right-0 w-full z-[0] h-full object-cover`}
          alt={textimage?.bg_image?.data?.attributes?.alternativeText}
          type="isDesktop"
        />
      )}
      <Container className="relative z-[1]">
        <div className="grid lg:grid-cols-12 grid-cols-6 lg:gap-10 gap-5">
          <div className="lg:col-span-12 col-span-6">
            {textimage?.titleEditor ? (
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                className={`${isWhite && 'text-[#ffffff]'}  font-[400] text-center xl:text-[55px] md:text-[32px] text-[22px] title_hd leading-tight lg:mb-4 md:mb-4 mb-2 max-w-[1100px] mx-auto`}
                source={textimage?.titleEditor}
              >
                {textimage?.titleEditor}
              </ReactMarkdown>
            ) : (
              <h4
                className={`relative font-[800] text-[#313131] xl:text-[55px] lg:text-[32px] text-[22px] text-left leading-tight max-w-[1100px] mx-auto ${textimage?.descriptionEditor ? 'mb-6' : 'mb-3'}`}
              >
                <span
                  className={`w-[100%] relative block ${isHomeFont ? 'font-[800]' : 'font-bold'} ${textimage?.bgText ? 'mt-[-30px]' : ''}`}
                >
                  {textimage?.title}
                </span>
              </h4>
            )}
            {textimage?.bgText && (
              <p className="md:text-[22px] text-[14px] font-[400] text-[#313131] text-center">
                {textimage?.bgText}
              </p>
            )}
          </div>
          <div className="col-span-7 flex flex-wrap items-center">
            <div>
              <div
                className="border-b border-dashed border-[#B8B8B8] pb-5 csswithtag  font-[400] lg:text-[20px] text-[16px] anchorInPage expert_team"
                dangerouslySetInnerHTML={createMarkup(
                  textimage?.descriptionEditor
                )}
              ></div>
              {textimage?.Accord &&
                textimage?.Accord?.map((faqtext, index) => (
                  <Accordion
                    key={faqtext?.id}
                    faqtext={faqtext}
                    showItems={index === showIndex ? true : false}
                    setShowIndex={() => {
                      setShowIndex(index);
                    }}
                    isBgBlack={isBgBlack}
                  />
                ))}
              {textimage?.Reasons && (
                <div className="grid grid-cols-2 gap-6 border-dashed border-[#FFFFFF3D] border-y-[.3px] my-[40px] py-[30px]">
                  {textimage?.Reasons?.map((items, key) => (
                    <div key={items?.id} className="text-center">
                      <p className="lg:text-[30px] text-[24px] text-[#fff] font-[600]">
                        {items?.text}
                      </p>
                      <p className="text-[16px] text-[#fff] font-[400] max-w-[250px] mx-auto">
                        {items?.description}
                      </p>
                    </div>
                  ))}
                </div>
              )}
              <div className="mt-10">
                {textimage?.btn_link ? (
                  <Link
                    color="gray"
                    href={`${textimage?.btn_link}`}
                    // style={{
                    //   backgroundColor: textimage?.btn_color,
                    // }}
                    className="text-white bg-[#FFA030] border border-transparent hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:disabled:hover:bg-blue-600 focus:!ring-2 group h-min p-0.5 text-center font-medium focus:z-10 blue_btn text-[#ffffff_!important] relative rounded overflow-hidden text-center inline-block shadow-[10px_20px_40px_0px_#00000033]"
                  >
                    <span className="flex items-center rounded-md text-sm px-7 py-4">
                      <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-[#fff] group-hover:h-full opacity-1"></span>
                      <span className="font-[600] relative group-hover:text-black flex items-center lg:text-[20px] text-[14px]">
                        {textimage?.btn_text}
                        <svg
                          className={`ml-2 stroke-[white] group-hover:stroke-[black]`}
                          width="16"
                          height="16"
                          viewBox="0 0 18 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 1.5L16 10.3684L8.5 19.2368"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.09375 1.5L9.59375 10.3684L2.09375 19.2368"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </span>
                  </Link>
                ) : textimage?.btn_text ? (
                  <button
                    className={`bg-[#FFA030] text-[#000D3A] border border-[#FFFFFF] group h-min text-center relative rounded-[4px] overflow-hidden inline-block px-10 py-2 shadow-[10px_20px_40px_0px_#00000033] md:block hidden`}
                    onClick={() => setModalCall(textimage?.btn_text)}
                  >
                    <span
                      className={`bg-[#fff] absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1`}
                    ></span>
                    <span
                      // style={{textShadow: "1px 1px 4px #1113"}}
                      className={`lg:text-[24px] text-[14px] group-hover:text-[000000] flex items-center font-[600] relative`}
                    >
                      {textimage?.btn_text}
                    </span>
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="col-span-5 md:block hidden">
            {textimage?.image?.data?.attributes?.url && (
              <CloudinaryImage
                backendImgUrl={textimage?.image?.data?.attributes?.url}
                className="max-w-[500px] mx-auto w-full"
                alt={textimage?.image?.data?.attributes?.alternativeText}
                type="smallimg"
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
